<!--
 * @Description: 产品详情 - 数据解析
 * @Author: kecraft
 * @Date: 2024-03-05 14:53:58
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:50:27
 * @FilePath: /impact-iotos-console/src/views/product/components/dataAnalysis/dataAnalysis.vue
-->

<template>
  <div class="data-shaping">
    <div class="tooltip1-container">
      <img src="@/assets/icon/tooltip-icon.png" alt="">
      <div>编写数据解析脚本，设备上报数据时会自动调用脚本将数据解析为JSON格式，您可以对脚本进行模拟和运行调用，运行正常后点击“提交”，发布该脚本。raw2Protocol支持返回string或者object类型</div>
    </div>
    <div class="mt20" v-if="form.draftUpdateDate">
      <div class="tooltip2-container">
        <img src="@/assets/icon/tooltip-icon-1.png" alt="">
        <div>您正在查看的脚本是线上正在运行的版本，该脚本的最近一次草稿保存于{{
      form.draftUpdateDate
    }}，草稿不会用于数据解析，您可以选择从草稿中<span class="btn-draft" @click="handleEdit" style="color: #367CC5;" v-domPreventReClick>恢复编辑</span>或<span
            class="btn-draft" @click="handleDel" style="color: #D92020;" v-domPreventReClick>删除草稿</span></div>
      </div>
    </div>

    <div class="code-container">
      <div style="width: 70%;">
        <div class="tooltip-container">
          <div class="tooltip-title">编辑脚本</div>
          <div class="tooltip-right">
            <div class="demo-btn" @click="handleLookDemo" v-domPreventReClick>查看示例</div>
            <div style=" color: rgba(255,255,255,.6);">（语法：JavaScript）</div>
          </div>
        </div>
        <div class="height950">
          <Codemirror class="code" v-model="form.script" :style="{ height: '100%' }" :extensions="extensions"
            :autofocus="true" :disabled="false" />
        </div>
      </div>
      <div class="code-right">
        <div class="height250">
          <div class="tooltip-container">
            <div class="tooltip-title">
              <div>模拟数据</div>
            </div>
            <div class="mocktype-container">
              <div>模拟类型：</div>
              <el-select v-model="mockType" placeholder="请选择" style="width: 120px">
                <el-option label="模拟上报" value="DEV_UP" />
                <el-option label="模拟下发" value="DEV_DOWN" />
              </el-select>
            </div>
          </div>
          <Codemirror class="code" v-model="simulateCode" :style="{ height: '100%' }" :extensions="extensions"
            :autofocus="false" :disabled="false" />
        </div>
        <div class="height400">
          <div class="tooltip-container">
            <div class="tooltip-title">运行结果</div>
            <div class="tooltip-right">
              <div style=" color: rgba(255,255,255,.6);"> <el-button type="primary" text
                  @click="copyToClipboard(resultCode)" v-domPreventReClick>复制</el-button></div>
            </div>
          </div>
          <Codemirror class="code" v-model="resultCode" :style="{ height: '100%' }" :extensions="extensions"
            :autofocus="false" :disabled="true" />
        </div>
      </div>
    </div>
    <div class="btn-container">
      <div style="width: 70%;">
        <div class="left-btns">
          <div class="btn-blue" @click="submit" v-domPreventReClick>提交</div>
          <div class="btn-yellow" @click="save" v-domPreventReClick>保存草稿</div>
        </div>
      </div>
      <div style="width: 28%;" class="run-btn">
        <div class="btn-green" @click="run" v-domPreventReClick>运行</div>
      </div>
    </div>
    <dataShapingDemo v-if="isDemo" @close="() => isDemo = false" />
  </div>
</template>

<script setup>
import dataShapingDemo from "./dataShapingDemo";
import { ref, reactive, onMounted } from 'vue';
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import api from "@/api/api";
import { ElMessage, ElMessageBox } from "element-plus";
import { js_beautify } from 'js-beautify'
import { copyToClipboard } from "@/hooks/common";
import { useRoute } from "vue-router";
const mockType = ref("DEV_UP")
class DiffSandbox {
  constructor(name) {
    this.name = name
    this.modifiedProps = {}
    this.windowSnapshot = {}
  }

  activeSandbox() {
    this.windowSnapshot = {}
    for (let key in window) {
      this.windowSnapshot[key] = window[key]
    }

    Object.keys(this.modifiedProps).forEach(propName => {
      window[propName] = this.modifiedProps[propName]
    })
  }

  inactiveSandbox() {
    for (let key in window) {
      if (this.windowSnapshot[key] !== window[key]) {
        this.modifiedProps[key] = window[key]
        window[key] = this.windowSnapshot[key]
      }
    }
  }
}
const route = useRoute();
const resultCode = ref("");
const isDemo = ref(false);
const simulateCode = ref("");
const extensions = [javascript(), oneDark];
const form = reactive({
  script: "",
  draft: "",// 草稿
  draftUpdateDate: "",// 草稿时间
})
const productKey = ref(null);
onMounted(() => {
  const { pk } = route.query;
  // const code = `function gbkString(bytes) {
  //   if (typeof TextDecoder !== 'undefined') {
  //     let decoder = new TextDecoder('GBK');
  //     const byteArr = new Uint8Array(bytes)
  //     return decoder.decode(byteArr);
  //   } else {
  //     // 对于不支持TextDecoder的浏览器，可以使用polyfill库，例如text-encoding或者手动转换字节到字符
  //     throw new Error('Your browser does not support TextDecoder API.');
  //   }
  // }`
  // window.eval(code);
  // const str = window.raw2Protocol([0xD6, 0xD0, 0xCE, 0xC4])
  // console.log(str);
  productKey.value = pk;
  if (!pk) return;
  getScript();
})

const getScript = () => {
  api.productScript(productKey.value).then(res => {
    if (res.code === "0") {
      const { formal, draft, draftUpdateDate } = res.res.data;
      form.script = formal; // 正式内容
      form.draft = draft;
      form.draftUpdateDate = draftUpdateDate;
    }
  })
}
const handleLookDemo = () => {
  isDemo.value = true;
}
const submit = () => {
  api.productScriptSave(productKey.value, { "script": form.script }).then(res => {
    if (res.code === "0") {
      ElMessage.success("提交成功！");
      getScript();
    }
  })
}
const handleEdit = () => {
  form.script = form.draft;
}
const handleDel = () => {
  ElMessageBox.confirm("确定删除该草稿吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.draftScriptDelete(productKey.value).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("删除成功!")
        getScript()
      }
    })
  })
}
const run = () => {
  const diffSandbox = new DiffSandbox('diff沙箱')
  // 激活沙箱
  diffSandbox.activeSandbox()
  const code = form.script;
  window.eval(code) // 挂载
  if (!simulateCode.value) return;
  const params = simulateCode.value
  try {
    // if (!params.params) return;
    // const data = {
    //   params: params.params
    // }
    if (mockType.value === "DEV_UP") {
      try {
        const result = window.raw2Protocol(params)
        console.log("123123", result);
        if (result) {
          console.log("123", result);
          const json = JSON.stringify(result)
          resultCode.value = js_beautify(json);
        }
      } catch (err) {
        console.log(err);
      }

    } else {
      const result = window.protocol2Raw(params)
      if (result) {
        const json = JSON.stringify(result)
        resultCode.value = js_beautify(json);
      }
    }

  } catch (error) {
    console.log(error);
    ElMessage.error(error)
  }
  // 关闭沙盒
  diffSandbox.inactiveSandbox()
}
const save = () => {
  api.productDraftScriptSave(productKey.value, { 'script': form.script }).then(res => {
    if (res.code === "0") {
      ElMessage.success("保存成功！");
      getScript();
    }
  })
}
</script>

<style lang="less" scoped>
.mt20 {
  margin-top: 10px;
}

.run-btn {
  display: flex;
  justify-content: center;
}

.height400 {
  height: 280px;
  margin-top: 50px;
}

.height250 {
  height: 170px;
}

.height950 {
  height: 500px;
}

.tooltip1-container {
  box-sizing: border-box;
  border: 1px solid #DDE6F3;
  width: 100%;
  height: 36px;
  background: #E5EBF4;
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 14px;
  padding-left: 20px;

  img {
    width: 13px;
    margin-right: 10px;
  }
}

.tooltip2-container {
  box-sizing: border-box;
  border: 1px solid #FADACC;
  width: 100%;
  height: 36px;
  background: #FBEEE8;
  display: flex;
  align-items: center;
  color: #FC8D1C;
  font-size: 14px;
  padding-left: 20px;

  img {
    width: 13px;
    margin-right: 10px;
  }
}

.mocktype-container {
  display: flex;
  align-items: center;

  &>div {
    color: #ffffff;
  }
}

.data-shaping {
  box-sizing: border-box;
  padding: 30px 50px;
}

p {
  line-height: 24px;
  font-size: 14px;
}

.code-right {
  width: 28%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.code-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.btn-container {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  .left-btns {
    justify-content: center;
    display: flex;
    align-items: center;

    &>div:last-child {
      margin-left: 30px;
    }
  }

  .btn-normal {
    text-align: center;
  }
}

.btn-blue {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  background: #367CC5;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}

.btn-yellow {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  background: #FC8D1C;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}

.btn-green {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  background: #30AE7D;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}

.tooltip-container {
  width: 100%;
  height: 40px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: #141b29;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  justify-content: space-between;

  .tooltip-title {
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }

  .demo-btn {
    cursor: pointer;
    width: 80px;
    text-align: center;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    font-size: 14px;
    color: rgb(62, 255, 255);
    border: 1px solid rgb(62, 255, 255);
    margin-right: 10px;
  }

  .tooltip-right {
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.btn-draft {
  color: #00c1de;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}
</style>