<!--
 * @Description: topic列表
 * @Author: kecraft
 * @Date: 2024-01-08 18:02:10
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 14:48:00
 * @FilePath: /impact-iotos-console/src/views/product/components/topic/topicList.vue
-->
<template>
  <div class="topic-list">
    <div class="tooltip-container">
      <img src="@/assets/icon/tooltip-icon.png" alt="">
      <div>设备与服务端之间通过Topic来实现消息通信。Topic类是针对产品的概念。产品的Topic类会自动映射到产品下的设备中，生成用于消息通信的具体设备Topic。</div>
    </div>
    <div class="tables">
      <el-table stripe :data="topicList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
        <el-table-column min-width="120" label="Topic类" prop="link" align="left">
          <template #default="scope">
            <div class="copy-box">
              <span class="normal-text">{{ scope.row.link }}</span><span class="copy-btn"
                @click="copyToClipboard(scope.row.link)">复制</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="200" label="操作权限" prop="action" align="center" />
        <el-table-column width="200" label="描述" prop="desc" align="left" show-overflow-tooltip />
      </el-table>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from "vue-router";
import api from '@/api/api';
import { copyToClipboard } from "@/hooks/common";
const route = useRoute();
const topicList = ref([]);
const isload = ref(true);
const getInfo = () => {
  const { pk } = route.query;
  if (!pk) return;
  api.getProductDetailsByPk(pk).then(res => {
    if (res.code === "0") {
      const { topicLinks } = res.res.data;
      topicList.value = topicLinks;
      isload.value = false;
    }
  })
}
getInfo();
</script>

<style lang="less" scoped>
.tooltip-container {
  box-sizing: border-box;
  border: 1px solid #DDE6F3;
  width: 100%;
  height: 36px;
  background: #E5EBF4;
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 14px;
  padding-left: 20px;

  img {
    width: 13px;
    margin-right: 10px;
  }
}

.copy-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topic-list {
  box-sizing: border-box;
  padding: 30px 50px;
}

.normal-text {
  display: inline-block;

}

.copy-btn {
  display: inline-block;
  width: 50px;
  height: 30px;
  line-height: 30px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  margin-left: 25px;
  color: #015EE0;
}

.copy-btn:hover,
.copy-btn:active {
  background: rgba(1, 94, 224, .06);
}

.tables {
  margin-top: 30px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 45px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}
</style>