<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-02-02 17:20:48
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:50:15
 * @FilePath: /impact-iotos-console/src/views/product/components/dataAnalysis/dataShapingDemo.vue
-->
<template>
  <div class="shaping-demo">
    <myDialog @close="handleClose" @ok="handleOk" title="示例" width="700px" okTitle="复制">
      <div class="width700">
        <Codemirror class="code" v-model="script" :style="{ height: '500px' }" :extensions="extensions"
          :autofocus="true" :disabled="true" />
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import { copyToClipboard } from "@/hooks/common";
import myDialog from "@/components/dialog/myDialog.vue";
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import { js_beautify } from 'js-beautify';
import { defineEmits } from "vue";
const emit = defineEmits(["close"]);
const extensions = [javascript(), oneDark];
const script = js_beautify(`
// 内置函数
// String gbkString(byte[] bytes) 将字节数组转为 gbk 字符串
// String utf8String(byte[] bytes) 将字节数组转为 utf8 字符串
// byte[] utf8Bytes(String str) 将字符串按照 utf8 解析成字节数组
// byte[] gbkBytes(String str) 将字符串按照 gbk 解析成字节数组
// 使用方式 stringUtil.xx，比如 string.utf8String 。

// JSON.parse， JSON.stringify 用于解析 json 数据。

// strToBytes 和 bytes2String 紧紧用于简单的 assii 编码，不支持中文测试；如果需要请提交之后观察上下行数据

function strToBytes (str) {
  var arr = []
  for (var i = 0; i < str.length; i++) {
    arr.push(str[i].charCodeAt());
  }
  return arr
}

function bytes2String(bytes){
  var str = "";
  for (i=0;i<bytes.length;i++) {
    str += String.fromCharCode(bytes[i])
  }

  return str;
}

/*
示例数据：
设备上报数据 （设备原始数据 --> klink格式数据)
传入参数 ->
    [123,34,97,99,116,105,111,110,34,58,34,100,101,118,83,101,110,100,34,44,34,109,115,103,73,100,34,58,49,44,34,112,107,34,58,34,51,54,49,55,102,54,99,51,53,55,102,57,52,53,102,48,56,51,54,50,49,51,53,55,53,53,48,51,54,53,98,48,34,44,34,100,101,118,73,100,34,58,34,55,49,52,51,57,51,48,51,48,49,48,48,48,48,56,34,44,34,100,97,116,97,34,58,123,34,99,109,100,34,58,34,114,101,112,111,114,116,80,111,119,101,114,34,44,34,112,97,114,97,109,115,34,58,123,34,112,111,119,101,114,34,58,48,44,34,104,117,109,34,58,34,111,110,101,34,44,34,108,105,103,116,104,34,58,54,57,46,51,125,125,125]
输出结果 ->
    {"action":"devSend","msgId":1,"pk":"3617f6c357f945f083621357550365b0","devId":"714393030100008","data":{"cmd":"reportPower","params":{"power":0,"hum":"one","ligth":69.3}}}
*/
function raw2Protocol(data) {
 var str= stringUtil.utf8String(data);
 return JSON.parse(str);
}
/*
示例数据：
设备下发数据 （klink格式数据 --> 设备自定义数据)
传入参数 ->
    {"action":"cloudSend","msgId":0,"pk":"3617f6c357f945f083621357550365b0","devId":"714393030100008","data":{"cmd":"setData","params":{"data":"one"}}}
输出结果 ->
    [111,110,101]
*/
function protocol2Raw (data) {
  if (data.action=='devSendResp'){
    return strToBytes(data["code"]+"");
  }else if (data.action=='cloudSend'){
    return stringUtil.utf8Bytes(JSON.stringify(params));
  }
  return  stringUtil.utf8Bytes(JSON.stringify(data));
}
`)
const handleClose = () => {
  emit("close");
}
const handleOk = () => {
  copyToClipboard(script)
}

</script>

<style lang="less" scoped>
.width700 {
  width: 700px;
}
</style>