<!--
 * @Description: 产品名称-详情
 * @Author: kecraft
 * @Date: 2024-01-03 16:22:31
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:35:10
 * @FilePath: /impact-iotos-console/src/views/product/details.vue
-->

<template>
  <div class="details">
    <div class="breadcrumb-conatiner">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/product/product/list' }">产品管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{ pkName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="details-container">
      <div class="top-container">
        <div class="tabs-conatiner">
          <div v-for="i in tabs" :key="i" class="tab-item" :class="i !== selectIndex ? 'is_select' : ''"
            @click="handleTabChanged(i)" v-domPreventReClick>
            <div>{{ i }}</div>
            <div class="line" v-if="i === selectIndex"></div>
            <div class="line-none" v-else></div>
          </div>
        </div>
      </div>
      <div class="main-container">
        <el-scrollbar style="height: 100%">
          <feature v-if="selectIndex === '功能定义'" />
          <overview v-if="selectIndex === '产品概览'" />
          <topicList v-if="selectIndex === 'Topic列表'" />
          <dataShaping v-if="selectIndex === '数据整形'" />
          <remoteConfig v-if="selectIndex === '远程配置'" />
          <serviceSubscribe v-if="selectIndex === '服务端订阅'" />
          <dataAnalysis v-if="selectIndex === '数据解析'" />
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import feature from './components/feature/feature';
import overview from './components/overview/overview';
import topicList from './components/topic/topicList';
import dataShaping from './components/DataShaping/dataShaping';
import remoteConfig from './components/remoteConfig/remoteConfig';
import serviceSubscribe from './components/subscribe/serviceSubscribe';
import dataAnalysis from './components/dataAnalysis/dataAnalysis';
import { onMounted, ref } from 'vue';
import { useRoute } from "vue-router";
import api from '@/api/api'
const route = useRoute();
const pkName = ref("产品名称");
const selectIndex = ref("");
const tabs = ref(["产品概览", "功能定义", "Topic列表", "服务端订阅", "数据整形", "远程配置"]);
onMounted(() => {
  const lastSelect = sessionStorage.getItem("handleTabsSelect");
  const { name, pk } = route.query
  pkName.value = name;
  if (lastSelect) {
    selectIndex.value = lastSelect
  } else {
    selectIndex.value = "产品概览"
  }
  if (!pk) return
  getInfo(pk)
})
const getInfo = (pk) => {
  api.getProductDetailsByPk(pk).then(res => {
    if (res.code === "0") {
      const info = res.res.data;
      const { dataFormat } = info;
      if (dataFormat === "CUSTOM") {
        tabs.value = ["产品概览", "功能定义", "Topic列表", "服务端订阅", "数据解析", "数据整形", "远程配置"];
      }
    }
  })
}
const handleTabChanged = (v) => {
  if (v === selectIndex.value) return;
  selectIndex.value = v;
  sessionStorage.setItem("handleTabsSelect", v)
}
</script>

<style lang="less" scoped>
.breadcrumb-conatiner {
  box-sizing: border-box;
  padding: 20px 28px;
  border-bottom: 1px solid #E9ECF0;
  width: 100%;
}

.details {
  width: 100%;
  box-sizing: border-box;
}

.tabs-conatiner {
  display: flex;
  box-sizing: border-box;
  padding-top: 23px;
  padding-left: 30px;
  margin-left: 19px;
  margin-right: 19px;
  border-bottom: 1px solid #EEEEEE;

  .top-container {
    border-bottom: 1px solid #EEEEEE;
  }

  .tab-item {
    text-align: center;
    line-height: 25px;
    box-sizing: border-box;
    margin-right: 30px;
    font-size: 18px;
    cursor: pointer;
    // border: 3px solid transparent;
    // margin-bottom: 12px;
  }

  .line {
    margin-top: 12px;
    width: 100%;
    height: 3px;
    background: #367CC5;
  }

}

.main-container {
  height: 80vh;
  background: #fff;
}
</style>