<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-01-10 16:57:07
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:48:31
 * @FilePath: /impact-iotos-console/src/views/product/components/DataShaping/dataShaping.vue
-->

<template>
  <div class="data-shaping">
    <div class="tooltip1-container">
      <div class="top-conatiner">
        <img src="@/assets/icon/tooltip-icon.png" alt="">
        <div>数据整形，即（DLink）数据后置处理器，可以使用（JavaScript）脚本方式修改解析后的数据，并将最终修改后的数据作为结果存储转发。</div>
      </div>
      <div class="bottom-container">
        <div>
          <p class="tooltip-title">使用场景：</p>
          <p>场景1：将上报的参数进行简单放大或缩小</p>
          <p>场景2：通过设备上报的参数a,b的值计算出c的值</p>
        </div>
        <div>
          <p class="tooltip-title">与自定义编解码不同：</p>
          <p>1.数据整形是对解析后的DLink数据处理。</p>
          <p>2.自定义编码只有自定义数据产品才有这个功能，数据整形所有产品都支持。</p>
        </div>
      </div>
    </div>
    <div class="code-container">
      <div style="width: 70%;">
        <div class="tooltip-container">
          <div class="tooltip-title">编辑脚本</div>
          <div class="tooltip-right">
            <div class="demo-btn" @click="handleLookDemo" v-domPreventReClick>查看示例</div>
            <div style=" color: rgba(255,255,255,.6);">（语法：JavaScript）</div>
          </div>
        </div>
        <div class="height950">
          <Codemirror class="code" v-model="form.script" :style="{ height: '100%' }" :extensions="extensions"
            :autofocus="true" :disabled="false" />
        </div>
      </div>
      <div class="code-right height950">
        <div class="height250">
          <div class="tooltip-container">
            <div class="tooltip-title">模拟数据</div>
          </div>
          <Codemirror class="code" v-model="simulateCode" :style="{ height: '100%' }" :extensions="extensions"
            :autofocus="true" :disabled="false" />
        </div>
        <div class="height400">
          <div class="tooltip-container">
            <div class="tooltip-title">运行结果</div>
            <div class="tooltip-right">
              <div style=" color: rgba(255,255,255,.6);"> <el-button type="primary" text
                  @click="copyToClipboard(resultCode)" v-domPreventReClick>复制</el-button></div>
            </div>
          </div>
          <Codemirror class="code" v-model="resultCode" :style="{ height: '100%' }" :extensions="extensions"
            :autofocus="true" :disabled="true" />
        </div>
      </div>
    </div>
    <div class="btn-container">
      <div style="width: 70%;">
        <div class="left-btns">
          <div class="btn-blue" @click="submit" v-domPreventReClick>提交</div>
          <div class="btn-green" style="margin-left: 20px;" v-if="!form.enable" @click="changeEnable" v-domPreventReClick>{{ form.enable ?
              "禁用" : "启用" }}
          </div>
          <div class="btn-red" style="margin-left: 20px;" v-if="form.enable" @click="changeEnable" v-domPreventReClick>{{ form.enable ?
              "禁用" : "启用" }}
          </div>
        </div>
      </div>
      <div style="width: 28%;" class="run-btn">
        <div class="btn-blue" @click="run" v-domPreventReClick>运行</div>
      </div>
    </div>
    <dataShapingDemo v-if="isDemo" @close="() => isDemo = false" />
  </div>
</template>

<script setup>
import { ElMessage } from "element-plus";
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import { js_beautify } from 'js-beautify'
import { onMounted, reactive, ref } from "vue";
import { useRoute } from "vue-router";
import { copyToClipboard } from "@/hooks/common";
import dataShapingDemo from "./dataShapingDemo";
import api from '@/api/api'
const route = useRoute();
const isDemo = ref(false);
const simulateCode = ref(null); // 模拟数据
const resultCode = ref(null);
const form = reactive({
  script: "",
  enable: false,
})
onMounted(() => {
  const { pk } = route.query;
  api.getDataShapeScript(pk).then(res => {
    if (res.code === "0") {
      if (res.res.data) {
        const { script, enable } = res.res.data;
        form.script = script;
        form.enable = enable;

        form.script = js_beautify(script);
        eval(form.script);
      } else {
        const json =
          `function shapeData(data, devId) {
    params = data.params;
    if (params.param1 == 15) {
        params.param1 = 16
    }
    return data
}`
        form.script = js_beautify(json);
      }
    }
  })

})
const extensions = [javascript(), oneDark];
const handleLookDemo = () => {
  isDemo.value = true;
}
class DiffSandbox {
  constructor(name) {
    this.name = name
    this.modifiedProps = {}
    this.windowSnapshot = {}
  }

  activeSandbox() {
    this.windowSnapshot = {}
    for (let key in window) {
      this.windowSnapshot[key] = window[key]
    }

    Object.keys(this.modifiedProps).forEach(propName => {
      window[propName] = this.modifiedProps[propName]
    })
  }

  inactiveSandbox() {
    for (let key in window) {
      if (this.windowSnapshot[key] !== window[key]) {
        this.modifiedProps[key] = window[key]
        window[key] = this.windowSnapshot[key]
      }
    }
  }
}

const run = () => {
  // diff 沙箱测试
  const diffSandbox = new DiffSandbox('diff沙箱')
  // 激活沙箱
  diffSandbox.activeSandbox()
  const code = form.script;
  window.eval(code) // 挂载shapeData
  if (!simulateCode.value) return;
  const params = JSON.parse(simulateCode.value)
  try {
    if (!params.params) return;
    const data = {
      params: params.params
    }
    const result = window.shapeData(data)
    if (result) {
      const json = JSON.stringify(result)
      resultCode.value = js_beautify(json);
    }
  } catch (error) {
    ElMessage.error(error)
  }
  // 关闭沙盒
  diffSandbox.inactiveSandbox()
}

/**
 * 修改启用状态
 */
const changeEnable = () => {
  form.enable = !form.enable;
  const { pk } = route.query;
  api.dataShapeScriptChange(pk, form).then(res => {
    if (res.code === "0") {
      const status = !form.enable ? "禁用成功！" : "启用成功！"
      ElMessage.success(status)
    }
  })

}
const submit = () => {
  const { pk } = route.query;
  api.dataShapeScriptChange(pk, form).then(res => {
    if (res.code === "0") {
      ElMessage.success("提交成功")
    }
  })
}
</script>

<style lang="less" scoped>
.tooltip1-container {
  box-sizing: border-box;
  border: 1px solid #DDE6F3;
  width: 100%;
  // height: 36px;
  background: #E5EBF4;
  color: #333333;
  font-size: 14px;
  padding: 14px 36px;

  .top-conatiner {
    display: flex;
    align-items: center;
  }

  .bottom-container {
    margin-top: 10px;
    display: flex;

    // align-items: center;
    &>div:last-child {
      margin-left: 20px;
    }
  }

  img {
    width: 13px;
    margin-right: 10px;
  }
}

.run-btn {
  display: flex;
  justify-content: center;
}

.height400 {
  height: 280px;
}

.height250 {
  height: 170px;
}

.height950 {
  height: 500px;
}

.data-shaping {
  box-sizing: border-box;
  padding: 30px 50px;
}

p {
  line-height: 24px;
  font-size: 14px;
}

.code-right {
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.code-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.btn-container {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;

  .left-btns {
    justify-content: center;
    display: flex;
    align-items: center;
  }

  .btn-normal {
    text-align: center;
  }
}

.tooltip-title {
  // line-height: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.tooltip-container {
  width: 100%;
  height: 40px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: #141b29;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  justify-content: space-between;

  .tooltip-title {
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }

  .demo-btn {
    cursor: pointer;
    width: 80px;
    text-align: center;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    box-sizing: border-box;
    font-size: 14px;
    color: rgb(62, 255, 255);
    border: 1px solid rgb(62, 255, 255);
    margin-right: 10px;
  }

  .tooltip-right {
    display: flex;
    align-items: center;
    color: #ffffff;
  }
}

.btn-blue {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  background: #367CC5;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}

.btn-gary {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  border: 1px solid #B7BBC0;
  color: #333333;
  cursor: pointer;
  border-radius: 4px;
}

.btn-green {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  background: #30AE7D;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}

.btn-red {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  background: #C52323;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}

.btn-normal :deep(.el-button) {
  font-size: 14px;
  width: 100px;
  height: 36px;
  background: #015ee0;
}

.btn-normal :deep(.el-button:hover) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}


.btn-normal :deep(.el-button:active) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}
</style>