<!--
 * @Description: 服务端订阅 - 添加
 * @Author: kecraft
 * @Date: 2024-03-05 14:32:20
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-27 13:49:23
 * @FilePath: /impact-iotos-console/src/views/product/components/subscribe/subscribeAdd.vue
-->

<template>
  <div class="subscribe-add">
    <myDialog @close="handleClose" @ok="handleOk" :title="title">
      <el-form :rules="rules" ref="subscribeAddRef" :model="form" label-position="left">
        <el-form-item label="订阅类型：" prop="ruleInfoType" class="label-feather">
          <el-select v-model="form.ruleInfoType" placeholder="请选择订阅类型" class="width500"
            :disabled="type === 3 || type === 2">
            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="订阅名称：" prop="desc" class="label-feather">
          <el-input v-model="form.desc" class="width500" placeholder="请输入订阅名称" :disabled="type === 3" />
        </el-form-item>
        <el-form-item label="订阅方URL地址：" prop="dest" class="label-feather">
          <el-input v-model="form.dest" class="width500" placeholder="请输入订阅方URL地址" :disabled="type === 3" />
        </el-form-item>
      </el-form>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { reactive, ref, defineEmits, defineExpose, onMounted } from "vue";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import api from '@/api/api'
const emit = defineEmits(["close"]);
const route = useRoute()
const title = ref("创建服务端订阅")
const subscribeAddRef = ref(null);
const type = ref(1); // 1:新增 2:编辑 3:查看
// CUSTOM, DATACHANGE, CLOUDRESP, DEVSEND, LOGINOUT
const typeList = ref([
  {
    label: "数据变化通知",
    value: "DATACHANGE",
  },
  {
    label: "控制响应通知",
    value: "CLOUDRESP",
  },
  {
    label: "数据上报通知",
    value: "DEVSEND",
  },
  {
    label: "上下线通知",
    value: "LOGINOUT",
  },
])
const form = reactive({
  pk: "",
  ruleInfoType: "",
  desc: "",
  dest: "",
})
const checkDest = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('订阅方URL地址不能为空'))
  } else {
    const reg = /^http:\/\/.*/g
    if (reg.test(value)) {
      callback()
    } else {
      return callback(new Error('请输入http://开头的URL地址'))
    }
  }
}
const rules = {
  ruleInfoType: [
    { required: true, message: "订阅类型不能为空", trigger: "change" },
  ],
  desc: [
    { required: true, message: "订阅名称不能为空", trigger: "change" },
    {
      min: 2,
      max: 24,
      message: '长度在 2 到 24 个字符',
      trigger: 'blur'
    }
  ],
  dest: [{ required: true, validator: checkDest, trigger: 'blur', }],
}


onMounted(() => {
  const { pk } = route.query;
  form.pk = pk;
})
const handleClose = () => {
  emit("close")
}
const handleOk = () => {
  if (!subscribeAddRef.value) return;
  subscribeAddRef.value.validate((valid) => {
    if (valid) {
      if (type.value === 1) {
        api.createDataSub(form).then(res => {
          if (res.code === "0") {
            ElMessage.success("创建成功！")
            emit("close", true)
          }
        })
      } else if (type.value === 3) {
        emit("close")
      } else if (type.value === 2) {
        if (!ruleId.value) return;
        api.dataSubEdit(ruleId.value, form).then(res => {
          if (res.code === "0") {
            ElMessage.success("编辑成功！")
            emit("close", true)
          }
        })
      }
    } else {
      return false;
    }
  });
}
const init = (row) => {
  // 详情
  title.value = "查看服务端订阅"
  type.value = 3;
  const { ruleInfoType, desc, dest } = row;
  form.ruleInfoType = ruleInfoType;
  form.desc = desc;
  form.dest = dest;
}
const ruleId = ref("");
const initEdit = (row) => {
  title.value = "编辑服务端订阅"
  type.value = 2;
  const oldForm = JSON.parse(JSON.stringify(row));
  ruleId.value = oldForm.ruleId
  form.ruleInfoType = oldForm.ruleInfoType;
  form.desc = oldForm.desc;
  form.dest = oldForm.dest;
}

defineExpose({ init, initEdit });
</script>

<style lang="less" scoped>
.width500 {
  width: 500px;
}
</style>
<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 140px;
  }
}
</style>