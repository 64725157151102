<!--
 * @Description: 产品详情-产品概览
 * @Author: kecraft
 * @Date: 2024-01-04 14:11:18
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-31 13:48:09
 * @FilePath: /impact-iotos-console/src/views/product/components/overview/overview.vue
-->
<template>
  <div class="product-overview">
    <!-- 基本信息 - 标题 -->
    <div>
      <div class="overview-item">
        <div class="overview-group">
          <div class="overview-left">
            <div class="overview-icon">
              <img src="@/assets/login/product.png" alt="">
            </div>
            <div class="overview-title">{{ productName || "--" }}</div>
          </div>
          <div class="edit-btn" @click="gotoEdit" v-domPreventReClick>编辑产品信息</div>
        </div>
      </div>
      <div class="pk-conatiner">
        <div class="overview-pk">产品pk：{{ productKey || "--" }}</div>
        <div class="copy-btn" @click="copyToClipboard(productKey)" v-domPreventReClick>复制</div>
      </div>
    </div>
    <!-- 基本信息 - 内容 -->
    <div class="info-container">
      <div class="info-item first-left ">
        <div class="item-left">产品品类</div>
        <div>{{ form.cidStr || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">联网方式</div>
        <div>{{ form.deviceLinkTypeStr || "--" }}</div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">交互协议</div>
        <div>{{ form.transferTypeStr || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">子品类名称</div>
        <div>{{ form.lastItem && form.lastItem.name || "--" }}</div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">子品类ID</div>
        <div>{{ form.lastItem && form.lastItem.customId || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">子品类编号</div>
        <div>{{ form.lastItem && form.lastItem.customCode || "--" }}</div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">型号</div>
        <div>{{ form.model || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">品牌</div>
        <div>{{ form.brand || "--" }}</div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">数据格式</div>
        <div>{{ form.dataFormatStr || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">设备类型</div>
        <div class="deviceType-box">
          <img src="@/assets/product/product_1.png" v-if="form.deviceType === 'GATEWAY'">
          <img src="@/assets/product/product_2.png" v-if="form.deviceType === 'TERMINAL'">
          <img src="@/assets/product/product_3.png" v-if="form.deviceType === 'GENERAL'">
          <img src="@/assets/product/product_4.png" v-if="form.deviceType === 'SWITCH'">
          {{ form.deviceTypeStr || "--" }}
        </div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">功能参数校验模式</div>
        <div>{{ form.dataCheckModeStr || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">设备登录安全校验</div>
        <div>{{ form.loginCheck ? '校验' : "不校验" }}</div>
      </div>
      <div class="info-item first-left ">
        <div class="item-left">动态注册设备</div>
        <div>{{ form.dynamicRegister ? '允许' : '不允许' }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">动态注册设备安全校验</div>
        <div>{{ form.registerCheck ? '开启' : '关闭' }}</div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">productSecret</div>
        <div class="item-right">
          <span>******</span> <span class="copy-btn" @click="copyToClipboard(form.productSecret)"
            v-domPreventReClick>复制</span>
        </div>
      </div>
      <div class="info-item">
        <div class="item-left">创建时间</div>
        <div>{{ form.createdDate || "--" }}</div>
      </div>
    </div>
    <!-- 设备接入信息 - 标题 -->
    <div class="overview-item">
      <div class="overview-icon">
        <img src="@/assets/login/device-into.png" alt="">
      </div>
      <div class="overview-title">设备接入信息</div>
    </div>
    <!-- 设备接入信息 - 内容 -->
    <div class="info-container1">
      <div class="info-item first-left" v-for="(item, index) of deviceLinksList" :key="index">
        <div class="item-left">{{ item.label }}</div>
        <div class="item-right">
          <div>
            <div v-for="(link, index) of item.links" :key="index" class="desc-content">
              <span>({{ link.schema }})</span>
              {{ link.host }}:{{ link.port }}
            </div>
          </div>
          <div class="look-btn" v-if="item.clientInfo">
            <el-button type="primary" text>查看接入信息</el-button>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用接入信息 - 标题 -->
    <div class="overview-item">
      <div class="overview-icon">
        <img src="@/assets/login/yingyong.png" alt="">
      </div>
      <div class="overview-title">应用接入信息</div>
    </div>
    <!-- 应用接入信息 - 内容 -->
    <div class="info-container1">
      <div class="info-item first-left" v-for="(item, index) of appLinksList" :key="index">
        <div class="item-left">{{ item.label }}</div>
        <div class="item-right1">
          <div v-for="(link, index) of item.links" :key="index" class="desc-content">
            <span>({{ link.schema }})</span>
            {{ link.host }}:{{ link.port }}{{ link.path }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { deviceLinkTypeList, transferTypeList, deviceTypeList, dataFormatList, dataCheckModeList } from "@/hooks/productEnum";
import { enumFilter, copyToClipboard } from "@/hooks/common";
import api from '@/api/api'
const router = useRouter();
const deviceLinksList = ref([]);
const appLinksList = ref([]);
const productKey = ref(null);
const productName = ref(null);
const route = useRoute();
onMounted(() => {
  /* 通过是否有PK判断是否是编辑 */
  const { pk, name } = route.query;
  productKey.value = pk;
  productName.value = name
  getInfo()
});

const gotoEdit = () => {
  const { pk } = route.query;
  router.push({
    path: "/product/product/add",
    query: { pk }
  })
}
const form = ref({});
const getInfo = () => {
  api.getProductDetailsByPk(productKey.value).then(res => {
    if (res.code === "0") {
      const info = res.res.data;
      const { deviceLinks, appLinks, categories } = info;
      deviceLinksList.value = deviceLinks;
      appLinksList.value = appLinks
      info.deviceLinkTypeStr = enumFilter(info.deviceLinkType, deviceLinkTypeList) || "--";
      info.transferTypeStr = enumFilter(info.transferType, transferTypeList) || "--";
      info.dataFormatStr = enumFilter(info.dataFormat, dataFormatList) || "--";
      info.deviceTypeStr = enumFilter(info.deviceType, deviceTypeList) || "--";
      info.dataCheckModeStr = enumFilter(info.dataCheckMode, dataCheckModeList) || "--";
      /* 子品类名称为categories最后一项 */
      let cids = []
      categories.forEach(item => {
        cids.push(item.name)
      })
      info.cidStr = cids.join("/");
      if (categories.length > 0) {
        info.lastItem = categories[categories.length - 1]
      }
      form.value = info;
    }
  })
}
</script>

<style lang="less" scoped>
.deviceType-box{
  display: flex;
  align-items: center;
  img{
    width: 19px;
    height: 20px;
    margin-right: 6px;
  }
}
.pk-conatiner {
  display: flex;
  align-items: center;

  .overview-pk {
    font-size: 16px;
    line-height: 22px;
    color: #333;
  }

  .copy-btn {
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: 50px;
    color: #367CC5;
    cursor: pointer;
  }
}

.product-overview {
  box-sizing: border-box;
  padding: 0 50px;

  .overview-item {
    // padding: 10px 0;
    display: flex;
    align-items: center;

    .overview-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .overview-left {
      display: flex;
      align-items: center;
    }

    .overview-icon {
      width: 18px;
      height: 20px;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .overview-title {
      font-weight: 700;
      font-size: 20px;
    }

    .copy-btn {
      // flex: 1;
      display: inline-block;
      width: 60px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      margin-left: 40px;
      color: #015ee0;
      cursor: pointer;
    }

    .copy-btn:hover,
    .copy-btn:active {
      background: rgba(1, 94, 224, .06);
    }
  }

  .info-container {
    margin-top: 15px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .first-left {
      border-left: 1px solid #DCE0EB;
    }

    &>div:nth-child(1) {
      border-top: 1px solid #DCE0EB;
    }

    &>div:nth-child(2) {
      border-top: 1px solid #DCE0EB;
    }

    .info-item {
      width: 50%;
      border-bottom: 1px solid #DCE0EB;
      border-right: 1px solid #DCE0EB;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      &>div {
        box-sizing: border-box;
        padding-right: 5px;
        word-break: break-all;
      }

      .item-left {
        padding: 20px 0 20px 15px;
        font-size: 16px;
        color: #656775;
        background: #F6F7F9;
        border-right: 1px solid #DCE0EB;
      }


      &>div:first-child {
        padding-left: 30px;
        font-size: 16px;
        color: #656775;
        width: 35%;
      }

      &>div:last-child {
        padding-left: 30px;
        font-size: 16px;
        color: #333333;
        width: 65%;
      }
    }


    .item-right {
      display: flex;
      // justify-content: space-between;
      align-items: center;

      span {
        font-size: 16px;
      }

      .copy-btn {
        text-align: center;
        display: inline-block;
        width: 40px;
        height: 20px;
        line-height: 20px;
        margin-left: 90px;
        color: #367CC5;
        cursor: pointer;
      }

      .copy-btn:hover,
      .copy-btn:active {
        background: rgba(1, 94, 224, .06);
      }
    }
  }
}

.info-container1 {
  margin-top: 15px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  .first-left {
    border-left: 1px solid #DCE0EB;
  }

  &>div:nth-child(1) {
    border-top: 1px solid #DCE0EB;
  }

  &>div:nth-child(2) {
    border-top: 1px solid #DCE0EB;
  }

  .info-item {
    background: #F6F7F9;
    width: 100%;
    border-bottom: 1px solid #DCE0EB;
    border-right: 1px solid #DCE0EB;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &>div {
      box-sizing: border-box;
      padding-right: 5px;
      word-break: break-all;
    }

    &>div:first-child {
      padding-left: 30px;
      font-size: 16px;
      color: #656775;
      width: 17.5%;
    }

    &>div:last-child {
      border-left: 1px solid #DCE0EB;
      background: #ffffff;
      padding-left: 30px;
      font-size: 16px;
      color: #333333;
      width: 82.5%;
    }
  }

  .item-right1 {
    box-sizing: border-box;
    padding: 18px 0;

    span {
      color: #FF8611 !important;
    }
  }

  .item-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 18px 0;

    span {
      color: #FF8611 !important;
      font-size: 16px;
    }

    .copy-btn {
      text-align: center;
      display: inline-block;
      width: 40px;
      height: 20px;
      line-height: 20px;
      margin-right: 10px;
      color: #367CC5;
      cursor: pointer;
    }

    .copy-btn:hover,
    .copy-btn:active {
      background: rgba(1, 94, 224, .06);
    }
  }
}

.deviceLinks-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .deviceLinks-group {
    display: flex;
    width: calc(100% / 3);

    &>div {
      width: 50%;
    }
  }
}

.desc-content {
  font-size: 16px;
  margin-top: 6px;
  line-height: 22px;
}

.desc-content:first-child {
  margin-top: 0;
}

.edit-btn {
  margin-top: 23px;
  cursor: pointer;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  box-sizing: border-box;
  border: 1px solid #367CC5;
  font-size: 16px;
  color: #367CC5;
  border-radius: 4px;
}

.look-btn {
  color: #367CC5;
  font-size: 16px;
}

.look-btn:deep(.el-button>span) {
  font-size: 16px;
  color: #367CC5;
}
</style>