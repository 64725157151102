<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-01-03 17:04:29
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:39:18
 * @FilePath: /impact-iotos-console/src/views/product/components/feature/feature.vue
-->
<template>
  <div class="feature">
    <div class="top-box">
      <div class="tabs-box">
        <div :class="selectName === '0' ? 'is-select' : ''" @click="handleTabs('0')" v-domPreventReClick>自定义参数</div>
        <div :class="selectName === '1' ? 'is-select' : ''" @click="handleTabs('1')" v-domPreventReClick>命令</div>
      </div>
    </div>
    <customList v-if="selectName === '0'" />
    <commandList v-if="selectName === '1'" />
  </div>
</template>

<script setup>
import customList from './custom/customList';
import commandList from './command/commandList';
import { ref } from 'vue';
const selectName = ref("0");
const handleTabs = (v) => {
  if (selectName.value === v) return;
  selectName.value = v;
}
</script>

<style lang="less" scoped>
.feature {
  box-sizing: border-box;
  padding: 30px 50px;
}

.top-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabs-box {
  box-sizing: border-box;
  width: 230px;
  height: 44px;
  border-radius: 6px;
  background: #EBEFF6;
  border: 3px solid #EBEFF6;
  display: flex;
  justify-content: space-between;

  &>div {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 16px;
    color: #303133;
    cursor: pointer;
  }

  .is-select {
    background: #367CC5;
    color: #ffffff;
  }
}
</style>