<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-02-22 14:08:03
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-27 13:50:37
 * @FilePath: /impact-iotos-console/src/views/product/components/remoteConfig/configDialog.vue
-->
<template>
  <div class="config-dialog">
    <myDialog @close="handleClose" @ok="handleOk" title="查看详情" width="700px" okTitle="恢复">
      <div class="dialog-box">
        <Codemirror class="code" v-model="script" :style="{ height: '500px' }" :extensions="extensions"
          :autofocus="true" :disabled="true" />
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import { js_beautify } from 'js-beautify';
import { defineEmits, ref, defineExpose } from "vue";
const emit = defineEmits(["close"]);
const extensions = [javascript(), oneDark];
const script = ref("");
const handleClose = () => {
  emit("close", false);
}
const handleOk = () => {
  emit("close", script.value)
}

const init = (row) => {
  const info = JSON.parse(JSON.stringify(row))
  script.value = js_beautify(info.content)
}
defineExpose({ init });
</script>

<style lang="less" scoped>
.dialog-box{
  width: 700px;
}
</style>