<template>
  <div class="remote-config">
    <div class="tooltip-container">
      <img src="@/assets/icon/tooltip-icon.png" alt="">
      <div>平台支持远程更新设备的配置文件(JSON格式)，您可以在下方编辑配置模板，对设备的系统参数、网络参数等进行远程配置，通过批量更新对设备进行批量远程维护和管理。</div>

    </div>
    <div class="search-container">
      <el-select v-model="deviceId" placeholder="请选择或数据设备名称/ID" class="width350" clearable @change="handleDeviceChange">
        <el-option v-for="item in deviceList" :key="item.devId" :label="item.name + '-' + item.devId"
          :value="item.devId" />
      </el-select>
      <div class="tooltip-right">
        本配置模板状态：
        <el-button type="success" plain v-if="isEnable">已启用</el-button>
        <el-button type="danger" plain v-if="!isEnable">已禁用</el-button>
      </div>
    </div>
    <div class="mirror-container">
      <div class="code-container">
        <div class="code-title">配置模板</div>
      </div>
      <Codemirror class="code" v-model="script" :style="{ height: '82%' }" :extensions="extensions" :autofocus="true"
        :disabled="noEdit" />
    </div>
    <div class="btn-groups" v-if="noEdit">
      <div class="btn-normal" @click="() => noEdit = false" v-domPreventReClick>编辑</div>
      <div class="btn-edit" @click="handleChangeStatus"> {{ isEnable ? "禁用" : "启用" }}
      </div>
    </div>
    <div class="btn-groups" v-if="!noEdit">
      <div class="btn-cancel" @click="() => noEdit = true" v-domPreventReClick>取消</div>
      <div class="btn-normal" @click="handleSave" v-domPreventReClick>保存并启用</div>
    </div>
    <div class="history-container">
      <div class="history-title">
        <img src="@/assets/icon/edit-icon.png" alt="">配置版本记录
      </div>
      <div class="tables">
        <el-table stripe :data="historyList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
          <el-table-column width="80" label="序号" align="center">
            <template #default="scope">
              <span>{{
        scope.$index + 1
      }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="200" label="版本创建时间" prop="createdDate" align="left" />
          <el-table-column min-width="120" label="操作" align="center">
            <template #default="scope">
              <div class="column-btn">
                <div class="options">
                  <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="check(scope.row)"
                    v-domPreventReClick>
                    查看
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <configDialog v-if="isCheck" ref="configDialogRef" @close="handleCheckClose" />
  </div>
</template>

<script setup>
import configDialog from "./configDialog"
import { ElMessage, ElMessageBox } from "element-plus";
import { onMounted, ref, getCurrentInstance, nextTick } from 'vue';
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import { useRoute } from 'vue-router';
import api from '@/api/api';
const route = useRoute();
const deviceId = ref(null);
const deviceList = ref([]);
const extensions = [javascript(), oneDark];
const script = ref(null);
const historyList = ref([]);
const isload = ref(false);
const productKey = ref(null);
const isEnable = ref(false);
const isCheck = ref(false);
const noEdit = ref(true);
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
  const { pk } = route.query
  productKey.value = pk
  if (!pk) return;
  getAllDeviceList();
  getConfig();
  getHistory();
})
const check = (row) => {
  isCheck.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.configDialogRef.init(row);
  });
}
const getAllDeviceList = () => {
  api.getDeviceByPk(productKey.value).then(res => {
    if (res.code === "0") {
      deviceList.value = res.res.data;
    }
  })
}
const handleDeviceChange = () => {
  if (!deviceId.value) deviceId.value = "";
  noEdit.value = true;
  getConfig();
  getHistory();
}

const handleCheckClose = (val) => {
  isCheck.value = false;
  if (val) {
    script.value = val;
  }
}

const getConfig = () => {
  let devId = deviceId.value
  if (!devId) devId = ""
  api.getProductConfig(productKey.value, {
    devId,
  }).then(res => {
    if (res.code === "0") {
      const { enable, content } = res.res.data;
      isEnable.value = enable;
      script.value = content;
    }
  })
}

const getHistory = () => {
  let devId = deviceId.value
  if (!devId) devId = ""
  api.getHistoryConfig(productKey.value, {
    devId,
  }).then(res => {
    if (res.code === "0") {
      historyList.value = res.res.data;
    }
  })
}

const handleChangeStatus = () => {
  if (!isEnable.value) {
    // 启用
    let isJson = true;
    if (!script.value) isJson = false;
    const list = script.value.split("\n");
    if (list.length > 0) {
      list.forEach(line => {
        if (line) {
          const status = isJSON(line)
          if (!status) {
            isJson = false;
            ElMessage.error("不是JSON格式")
          }
        }
      })
    }
    if (isJson) {
      let devId = deviceId.value
      if (!devId) devId = ""
      ElMessageBox.confirm("确定启用本配置模板吗?", "提示", {
        type: "warning",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        api.saveAndEnableConfig({
          pk: productKey.value,
          devId,
          content: script.value
        }).then(res => {
          if (res.code === "0") {
            ElMessage.success("启用成功！");
            noEdit.value = true;
            getConfig();
            getHistory();
          }
        })
      })
    }
  } else {
    // 禁用
    let devId = deviceId.value
    if (!devId) devId = ""
    ElMessageBox.confirm("确定禁用本配置模板吗?", "提示", {
      type: "warning",
      confirmButtonText: "确定",
      cancelButtonText: "取消",
    }).then(() => {
      api.disableConfig({
        pk: productKey.value,
        devId,
        content: script.value
      }).then(res => {
        if (res.code === "0") {
          ElMessage.success("禁用成功！");
          noEdit.value = true;
          getConfig();
          getHistory();
        }
      })
    })
  }
}

function isJSON(str) {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
}
const handleSave = () => {
  let isJson = true;
  if (!script.value) isJson = false;
  const list = script.value.split("\n");
  if (list.length > 0) {
    list.forEach(line => {
      if (line) {
        const status = isJSON(line)
        if (!status) {
          isJson = false;
          ElMessage.error("不是JSON格式")
        }
      }
    })
  }
  if (isJson) {
    let devId = deviceId.value
    if (!devId) devId = ""
    ElMessageBox.confirm("是否保存当前配置信息？保存后将立即配置批量更新到改产品下的所有设备，设备也可以主动获取配置", "提示", {
      type: "warning",
      confirmButtonText: "确定",
      cancelButtonText: "取消",
    }).then(() => {
      api.saveAndEnableConfig({
        pk: productKey.value,
        devId,
        content: script.value
      }).then(res => {
        if (res.code === "0") {
          ElMessage.success("保存并启用成功！");
          noEdit.value = true;
          getConfig();
          getHistory();
        }
      })
    })
  }
}
</script>

<style lang="less" scoped>
.history-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;

  img {
    width: 20px;
    margin-right: 12px;
  }
}

.mirror-container {
  height: 300px;
}

.tooltip-right {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.width350 {
  width: 350px;
}

.remote-config {
  box-sizing: border-box;
  padding: 30px 36px 30px 49px;
}

.tooltip-container {
  box-sizing: border-box;
  border: 1px solid #DDE6F3;
  width: 100%;
  height: 36px;
  background: #E5EBF4;
  display: flex;
  align-items: center;
  color: #333333;
  font-size: 14px;
  padding-left: 20px;

  img {
    width: 13px;
    margin-right: 10px;
  }
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.code-container {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: #141b29;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  justify-content: space-between;

  .code-title {
    line-height: 40px;
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
}

.btn-groups {
  display: flex;
  align-items: center;
  justify-content: center;

  div:first-child {
    margin-right: 20px;
  }
}


.history-container {
  margin-top: 40px;
}

.btn-normal {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  background: #367CC5;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}

.btn-cancel {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  border: 1px solid #B7BBC0;
  color: #333333;
  cursor: pointer;
  border-radius: 4px;
}

.btn-edit {
  box-sizing: border-box;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  background: #30AE7D;
  color: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
}

.tables {
  margin-top: 30px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}
</style>