<!--
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-02-02 17:20:48
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:49:03
 * @FilePath: /impact-iotos-console/src/views/product/components/DataShaping/dataShapingDemo.vue
-->
<template>
  <div class="shaping-demo">
    <myDialog @close="handleClose" @ok="handleOk" title="示例" width="700px" okTitle="复制">
      <div class="width700">
        <Codemirror class="code" v-model="script" :style="{ height: '500px' }" :extensions="extensions"
          :autofocus="true" :disabled="true" />
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import { copyToClipboard } from "@/hooks/common";
import myDialog from "@/components/dialog/myDialog.vue";
import { Codemirror } from "vue-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { oneDark } from "@codemirror/theme-one-dark";
import { js_beautify } from 'js-beautify';
import { defineEmits } from "vue";
const emit = defineEmits(["close"]);
const extensions = [javascript(), oneDark];
const script = js_beautify(`
function shapeData(data, devId) {
  params = data.params
  if (params.power == 1) {
    params.light = 100
    params.hum = "A"
  } else {
    params.light = 2
    params.hum = "B"
  }
}
// 模拟数据
{
  "cmd":"reprot",
  "params":{
    "power":1,
    "light":200,
    "hum":"自定义"
  }
}`)
const handleClose = () => {
  emit("close");
}
const handleOk = () => {
  copyToClipboard(script)
}

</script>

<style lang="less" scoped>
.width700 {
  width: 700px;
}
</style>