<!--
 * @Description: 产品详情 - 服务端订阅
 * @Author: kecraft
 * @Date: 2024-03-05 14:14:16
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:47:21
 * @FilePath: /impact-iotos-console/src/views/product/components/subscribe/serviceSubscribe.vue
-->

<template>
  <div class="service-subscribe">
    <div class="search-conatiner">
      <div class="left-form">
        <div class="search-container">
          <div class="form-item">
            <el-input v-model="query.keyword" class="input-item" placeholder="请输入参数名称/标识符" :prefix-icon="Search" />
          </div>
          <div class="form-item" style="flex: 1;">
            <div class="search-btn" @click="handleSearch" v-domPreventReClick>搜索</div>
            <div class="reset-btn" @click="reset" v-domPreventReClick>重置</div>
          </div>
        </div>
      </div>
      <div class="btn-green" @click="add" v-domPreventReClick>
        <img src="@/assets/icon/add.png">
        添加订阅
      </div>
    </div>

    <div class="tables">
      <el-table stripe :data="subscribeList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
        <el-table-column width="80" label="序号" align="center">
          <template #default="scope">
            <span>{{
              scope.$index + 1
            }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="150" label="订阅名称" prop="desc" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="订阅类型" prop="ruleInfoTypeStr" align="center" />
        <!-- <el-table-column min-width="200" label="所属产品" prop="desc" align="center" /> -->
        <el-table-column min-width="200" label="订阅方地址" prop="dest" align="left">
          <template #default="scope">
            <span>{{ scope.row.dest }}</span><span class="copy-btn" @click="copyToClipboard(scope.row.dest)" v-domPreventReClick>复制</span>
          </template>
        </el-table-column>
        <el-table-column width="200" label="是否启用" prop="enable" align="center" v-if="!isload">
          <template #default="scope">
            <div class="status-container">
              <el-switch v-model="scope.row.enable" class="ml-2"
                style="--el-switch-on-color: #13ce66;margin-right:10px;" @change="handleSwitchChange(scope.row)" />
              {{ scope.row.enable ? "已启用" : "已禁用" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="200" label="创建时间" prop="createdDate" align="center" />
        <el-table-column min-width="200" label="操作" align="center">
          <template #default="scope">
            <div class="column-btn">
              <div class="options">
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleDetail(scope.row)" v-domPreventReClick>
                  查看
                </span>
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleEdit(scope.row)" v-domPreventReClick>
                  修改
                </span>
                <span link style="color:#fc2b2b;cursor: pointer;padding: 0 6px;" @click="handleDel(scope.row)" v-domPreventReClick>
                  删除
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer-conatiner">
      <pagination :total="total" @pageChange="getList" class="pagination" layout="sizes, prev, pager, next" />
    </div>
    <subscribeAdd v-if="isAdd" @close="handleClose" ref="subscribeAddRefs" />
  </div>
</template>

<script setup>
import subscribeAdd from "./subscribeAdd"
import { onMounted, reactive, ref, getCurrentInstance, nextTick } from 'vue';
import pagination from "@/components/control/pagination";
import { Search } from '@element-plus/icons-vue';
import { ElMessage, ElMessageBox } from "element-plus";
import { copyToClipboard } from "@/hooks/common";
import { useRoute } from "vue-router";
import api from '@/api/api'
const route = useRoute()
const query = reactive({
  pk: "",
  desc: "",
  page: 0,
  size: 10,
})
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
  const { pk } = route.query
  query.pk = pk;
  if (!pk) return;
  search();
})

const handleSearch = () => {
  query.page = 0;
  query.size = 10;
  search();
}
const reset = () => {
  query.desc = "";
  search()
}
const typeList = [
  {
    label: "数据变化通知",
    value: "DATACHANGE",
  },
  {
    label: "控制响应通知",
    value: "CLOUDRESP",
  },
  {
    label: "数据上报通知",
    value: "DEVSEND",
  },
  {
    label: "上下线通知",
    value: "LOGINOUT",
  },
]
const isAdd = ref(false);
const total = ref(0);
const subscribeList = ref([]);
const isload = ref(true);
const search = () => {
  isload.value = true;
  api.pageGetDataSub(query).then(res => {
    if (res.code === "0") {
      const { totalElements, content } = res.res.data;
      total.value = totalElements;
      content.forEach(item => {
        typeList.forEach(type => {
          if (item.ruleInfoType === type.value) {
            item.ruleInfoTypeStr = type.label;
          }
        })
      })
      subscribeList.value = content;
      isload.value = false;
    }
  })
}
const add = () => {
  isAdd.value = true;
}
const handleSwitchChange = (row) => {
  const { ruleId, enable } = row;
  const older = enable;
  api.ruleIdEnable(ruleId, enable).then(res => {
    if (res.code === "0") {
      if (!older) {
        ElMessage.success("禁用成功！")
      } else {
        ElMessage.success("启用成功！")
      }
      search();
    } else {
      subscribeList.value.forEach(item => {
        if (item.ruleId === ruleId) {
          item.enable = !older;
        }
      })
    }
  })
}
const handleDetail = (row) => {
  isAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.subscribeAddRefs.init(row);
  });
}
const handleEdit = (row) => {
  isAdd.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.subscribeAddRefs.initEdit(row);
  });
}
const handleDel = (row) => {
  ElMessageBox.confirm("确定删除该订阅吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    api.ruleDelete(row.ruleId).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("删除成功!")
        search()
      }
    })
  })
}
const getList = (v) => {
  query.page = v.pageNum - 1
  query.size = v.pageSize
  search();
}

const handleClose = (v) => {
  isAdd.value = false;
  if (v) search()
}
</script>

<style lang="less" scoped>
.input-item {
  width: 260px;
}

.service-subscribe {
  box-sizing: border-box;
  padding: 20px 50px;
}

.search-conatiner {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left-form {
    display: flex;
    align-items: center;
  }

  .form-item {
    margin-right: 10px;
  }
}

.search-btn {
  cursor: pointer;
  width: 66px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  box-sizing: border-box;
  border: 1px solid #015ee0;
  font-size: 14px;
  color: #015ee0;
}

.search-btn:hover,
.search-btn:active {
  background: #015ee0;
  color: #fff;
}

.btn-green {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 136px;
  height: 40px;
  border-radius: 4px;
  background: #30AE7D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}

.tables {
  margin-top: 20px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 45px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}

.copy-btn {
  display: inline-block;
  width: 40px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  cursor: pointer;
  margin-left: 25px;
  color: #015ee0;
}

.copy-btn:hover,
.copy-btn:active {
  background: rgba(1, 94, 224, .06);
}

.footer-conatiner {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.search-container {
  // margin-top: 30px;
  display: flex;
  align-items: center;

  .form-item {
    display: flex;
    align-items: center;
    margin-right: 25px;

    .reset-btn {
      margin-left: 10px;
      cursor: pointer;
      width: 92px;
      height: 36px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      font-weight: lighter;
      border: 1px solid #AFB9CB;
      color: #333333;
      font-size: 16px;
    }

    .search-btn {
      margin-left: 10px;
      cursor: pointer;
      width: 92px;
      height: 36px;
      justify-content: center;
      display: flex;
      align-items: center;
      border-radius: 4px;
      box-sizing: border-box;
      background: #367CC5;
      font-weight: lighter;
      // border: 1px solid #015ee0;
      font-size: 14px;
      color: #FFFFFF;
      font-size: 16px;
    }

    .search-btn:hover,
    .search-btn:active {
      border: 1px solid #367CC5;
      background: #61A4E9;
      // color: #367CC5;
    }
  }

}
</style>